<template>
    <modal ref="modalEditarEtapa" titulo="Editar etapa" adicional="Guardar" @adicional="editarEtapa">
        <div class="row justify-content-center m-3 f-15">
                <div class="col-9 my-2">
                    <p class="input-label-top mb-3">Seleccione las configuraciones de la etapa:</p>
                    <ValidationProvider v-slot="{ errors }" name="configuracion" rules="required">
                        <el-select v-model="checkEstados" placeholder="Seleccionar etapa" size="small" class="w-100" multiple>
                            <el-option v-for="data in configuraciones" :key="data.id" :label="data.nombre" :value="data.id" />
                        </el-select>
                        <VeeError :text="errors[0]"/>
                    </ValidationProvider>
                </div>

            <!-- <div class="col-11 my-2">
                <p class="f-14 mb-3">Seleccione las configuraciones de la etapa:</p>
                <el-checkbox-group v-model="checkEstados" @change="saveConfiguration">
                    <el-checkbox v-for="data in configuraciones.configuraciones" :key="data.id" :label="data.id" :value="data.id" class="d-flex mb-2">
                        {{data.nombre}}
                    </el-checkbox>
                </el-checkbox-group>
            </div> -->
        </div>
    </modal>
</template>

<script>


import { mapGetters, mapActions } from 'vuex'

export default {
    data(){
        return {
            checkEstados: [],
            configuraciones:[],
            etapasConfiguraciones:[],
            etapa:{}
            
        }
    },
    computed:{
        ...mapGetters({
            addEtapa: 'obras/informacion/addEtapa',
            producto: 'obras/informacion/producto',
            etapas: 'obras/informacion/etapas',
        })
    },
    methods: {
        ...mapActions({
            Action_put_edit_etapas: 'obras/informacion/Action_put_edit_etapas',
            Action_get_etapas: 'obras/informacion/Action_get_etapas',
            Action_get_informacion_equipos: 'obras/informacion/Action_get_informacion_equipos',
        }),
        async toggle(etapa){
            this.etapa = etapa
            this.etapasConfiguraciones = etapa?.configuraciones.map(e => e.id_configuracion) ?? []
            await this.listarConfiguraciones(etapa)
            this.$refs.modalEditarEtapa.toggle()
        },
        async editarEtapa(){
            let payload = {
                id_etapa: this.etapa.id_etapa,
                id_configuracion: this.checkEstados,
                id_proyecto: this.$route.params.id,
                id_producto: this.producto.id_producto,
                id_oportunidades_producto_etapas: this.etapa.id,
                tabla: this.producto.tabla
            }

            await this.Action_put_edit_etapas(payload)
            await this.Action_get_informacion_equipos(this.$route.params.id)
            this.$emit('reloadStates')
            this.$refs.modalEditarEtapa.toggle()
        },
        agregarConfiguracion(){

        },
        async listarConfiguraciones(etapa){
            await this.Action_get_etapas(this.$route.params.id)
            this.configuraciones = this.etapas.find(e => e.id == etapa.id_etapa)?.configuraciones ?? []
            this.checkEstados = etapa.configuraciones.map(e => e.id_configuracion)

        },
        saveConfiguration(){
        }
    }
}
</script>

<style lang="css" scoped>
</style>
